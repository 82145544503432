import AboutPageContent from "../components/AboutPageContent";
import AnimatedPage from "../components/AnimatedPage";
import React from "react";
import { graphql } from "gatsby";

const UeberUnsPage = ({ data }) => {
  const { page } = data;
  const { title, description, featured, mapquery, intro, blocks } =
    page.frontmatter;

  return (
    <AnimatedPage>
      <AboutPageContent
        title={title}
        description={description}
        featured={featured}
        mapquery={mapquery}
        intro={intro}
        blocks={blocks}
      />
    </AnimatedPage>
  );
};

export default UeberUnsPage;

export const pageQuery = graphql`
  query {
    page: markdownRemark(fileAbsolutePath: { regex: "/ueber-uns.md/" }) {
      frontmatter {
        title
        description
        featured {
          childImageSharp {
            gatsbyImageData(
              width: 1024
              layout: FULL_WIDTH
              formats: [AUTO, WEBP, AVIF]
              quality: 80
              placeholder: BLURRED
            )
          }
        }
        mapquery
        intro
        blocks {
          type
          text
          title
          galleryImages {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 1024
                  layout: FULL_WIDTH
                  formats: [JPG]
                  quality: 80
                  aspectRatio: 1.5
                  placeholder: BLURRED
                )
              }
            }
            caption
          }
          entries {
            contact
            handover
            additional
            price
            floor
            area
            kind
            address
            rooms
            url
          }
        }
      }
    }
  }
`;
