import { GatsbyImage, getImage } from "gatsby-plugin-image";

import BlocksSection from "../components/BlocksSection";
import React from "react";
import remark from "remark";
import remarkBehead from "remark-behead";
import remarkHTML from "remark-html";

const AboutPageContent = ({
  title,
  description,
  featured,
  mapquery,
  intro,
  blocks,
}) => {
  const gatsbyImageData = getImage(featured);

  return (
    <>
      <div className="jumbotron">
        {typeof featured !== "string" ? (
          <GatsbyImage
            image={gatsbyImageData}
            className="jumbotron-image"
            alt=""
            imgStyle={{ objectPosition: "center center" }}
          />
        ) : (
          <div className="jumbotron-image">
            <img
              src={featured}
              style={{
                objectPosition: "center top",
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
              alt=""
            />
          </div>
        )}
        <div className="jumbotron-content-container">
          <div className="container grid-960 arrow">
            <div className="columns">
              <div className="column col-6 col-md-12">
                <div className="jumbotron-content">
                  <h1 className="font-hill-bold text-uppercase mb-10">
                    {title}
                  </h1>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="content section container grid-960">
        <div className="columns">
          <div className="column col-6 col-sm-12">
            <div className="map-container">
              <iframe
                title="map"
                width="100%"
                height="300"
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAWvkOTaE2SVXRwh9eJHOY0lg-XFZ3GVrk&q=${encodeURIComponent(
                  mapquery
                )}`}
              ></iframe>
            </div>
          </div>

          <div
            className="column col-6 col-sm-12"
            dangerouslySetInnerHTML={{
              __html: remark()
                .use(remarkBehead, { depth: 1 })
                .use(remarkHTML)
                .processSync(intro)
                .toString(),
            }}
          />
        </div>
      </section>

      <BlocksSection blocks={blocks} className="pt-0" />
    </>
  );
};

export default AboutPageContent;
